<template>
  <el-descriptions class="dialog" v-loading="loading" title="">
    <el-descriptions-item label="比赛名称：">
      {{ descriptions.name }}
    </el-descriptions-item>
    <el-descriptions-item label="主办方：">
      {{ descriptions.sponsor }}
    </el-descriptions-item>
    <el-descriptions-item label="电子邮箱：">
      {{ descriptions.email }}
    </el-descriptions-item>
    <el-descriptions-item label="比赛介绍：" :span="3">
      {{ descriptions.details }}
    </el-descriptions-item>
    <el-descriptions-item label="交易品种：">
      <!-- {{ descriptions.transactionTypeName }} -->
      <el-checkbox-group  v-model="transactionType" style="width: 100%;display: inline;" @change="gptypeChange" :disabled="title==='设置'">
          <el-checkbox :label="item.value" v-for="(item, index) in transactionTypeList"  :key="item.value + index" :disabled="item.disabled">{{item.label}}</el-checkbox>
        </el-checkbox-group>
    </el-descriptions-item>
    <el-descriptions-item label="基准：">
      {{ descriptions.benchmark }}
    </el-descriptions-item>
    <el-descriptions-item label="初始资金：">
      {{ descriptions.initialFunding + "元" }}
    </el-descriptions-item>
    <!--    <el-descriptions-item-->
    <!--      v-if="!isTeacher && descriptions.matchType === 1 && isApply" label="参赛方式：" :span="3"-->
    <!--    >-->
    <!--      <el-radio-group v-model="groupMode" @change="changeGroupMode" size="mini">-->
    <!--        <el-radio label="加入小组"></el-radio>-->
    <!--        <el-radio label="新建小组"></el-radio>-->
    <!--      </el-radio-group>-->
    <!--      <el-input-->
    <!--        style="margin-left: 8px;width: 240px"-->
    <!--        size="mini"-->
    <!--        v-if="groupMode === '新建小组'"-->
    <!--        placeholder="请输入小组名称"-->
    <!--        v-model="groupName"-->
    <!--        clearable-->
    <!--      />-->
    <!--      <spread-table-->
    <!--        v-else-->
    <!--        singelSelect-->
    <!--        highlight-->
    <!--        :tableColumn="columns"-->
    <!--        :tableData="groupTable"-->
    <!--        :smallType="false"-->
    <!--        :currentPage="0"-->
    <!--        @highlightChange="highlightChange"-->
    <!--      />-->
    <!--    </el-descriptions-item>-->
    <el-descriptions-item v-if="!isTeacher && isApply" label="" :span="3">
      <!--        :disabled="disabled"-->

      <el-button size="small" class="normal-btn" type="primary" @click="handleSubmit">确认报名</el-button>
      <el-button size="small" class="normal-btn" @click="handleClose">取消</el-button>
    </el-descriptions-item>
  </el-descriptions>
</template>

<script>
import SpreadTable from "@/components/SpreadTable";
import { defineComponent, onMounted, reactive, toRefs, computed } from "vue";
import { dialogData } from "../datas";
import { userInfo } from "../methods";
import {
  getCompetitionGroupList,
  addCompetitionGroup,
  joinCompetition,
} from "../apis";
import { ElMessage } from 'element-plus';

export default defineComponent({
  name: "dialog-form",
  components: {
    SpreadTable,
  },
  props: {
    descriptions: {
      default: {},
    },
    isTeacher: {
      default: false,
    },
    isApply: {
      default: false,
    }
  },
  setup(props, { emit }) {
    const data = reactive(dialogData);
    const disabled = computed(() => {
      return props.descriptions.matchType === 1 && !data.groupName;
    });
    const columns = [
      {
        name: "组名",
        text: "groupName",
      },
      {
        name: "小组人数",
        text: "columsInfo",
      },
      {
        name: "小组状态",
        text: "columsStatus",
      },
    ];
    onMounted(() => {
      data.transactionType = props.descriptions.transactionType == 1 ? ['gp'] : props.descriptions.transactionType == 2 ? ['zq'] : props.descriptions.transactionType == 3 ? ['qh'] : props.descriptions.transactionType == 6 ?['wh'] : props.descriptions.transactionType == 5 ?['gp','zq'] : props.descriptions.transactionType == 7 ?['wh','gp'] :props.descriptions.transactionType == 8 ?['wh','zq'] :props.descriptions.transactionType == 9 ?['wh','qh'] :props.descriptions.transactionType == 4 ?['gp','zq','qh'] :props.descriptions.transactionType == 10 ?['wh','gp','zq'] :props.descriptions.transactionType == 11 ?['wh','gp','qh'] :props.descriptions.transactionType == 12 ?['wh','qh','zq'] : props.descriptions.transactionType == 13 ?['wh','gp','zq','qh'] :[] 
      if (props.descriptions.matchType === 1) {
        data.groupMode = "加入小组";
        data.groupTable = [];
        changeGroupMode();
        getGroup();
      }
    });

    const changeGroupMode = () => {
      data.groupTable.forEach(group => {
        group.singelSelect = false
      })
      data.groupName = "";
    };

    const handleSubmit = () => {
      data.groupMode === "新建小组" ? addGroup() : join();
    };

    const handleClose = () => {
      emit("handleClose");
    };

    const highlightChange = (val) => {
      data.groupTable.forEach(group => {
        if (group.id === data.competitionGroupId) {
          group.singelSelect = false
        }
      })
      if (val.isJoinGroup) {
        data.groupName = val.groupName;
        data.competitionGroupId = val.id;
        val.singelSelect = ''
      } else {
        data.groupName = '';
        ElMessage.warning('该小组已满员！')
      }
    };

    const getGroup = () => {
      const reqData = {
        competitionId: props.descriptions.id,
      };
      getCompetitionGroupList(reqData)
        .then((res) => {
          const resData = res.data;
          if (resData.status === 0 || resData.code == 200) {
            data.groupTable = capGroupTable(resData.data)
          } else {
            ElMessage.error(resData.msg)
          }
          data.loading = false;
        })
        .catch((e) => {
          data.loading = false;
        });
    };

    const capGroupTable = groupTable => {
      groupTable.forEach(group => {
        group.columsInfo = `
          已有${group.currentGroupSize}人 /（${group.numberType} ${group.groupsNumber}人）
        `
        group.columsStatus = group.isJoinGroup ? '可加入' : '已满员'
        group.singelSelect = false
      });
      return groupTable
    }

    const addGroup = () => {
      data.loading = true;
      const reqData = {
        // groupName: data.groupName,
        competitionId: props.descriptions.id,
        userName: userInfo().userName,
        userId: userInfo().userId,
      };
      addCompetitionGroup(reqData)
        .then((res) => {
          const resData = res.data;
          if (resData.status === 0 || resData.code == 200) {
            ElMessage.success(resData.msg)
            handleClose();
          } else {
            ElMessage.error(resData.msg)
          }
          data.loading = false;
        })
        .catch((e) => {
          data.loading = false;
        });
    };

    const join = () => {
      data.loading = true;
      const reqData = {
        matchType: props.descriptions.matchType,
        // groupName: data.groupName,
        competitionId: props.descriptions.id,
        // competitionGroupId: data.competitionGroupId,
        userName: userInfo().userName,
        userId: userInfo().userId,
      };
      joinCompetition(reqData)
        .then((res) => {
          const resData = res.data;
          if (resData.status === 0 || resData.code == 200) {
            ElMessage.success(resData.msg)
            handleClose();
          } else {
            ElMessage.error(resData.msg)
          }
          data.loading = false;
        })
        .catch((e) => {
          data.loading = false;
        });
    };

    return {
      ...toRefs(data),
      disabled,
      columns,
      handleSubmit,
      handleClose,
      highlightChange,
      changeGroupMode,
    };
  },
});
</script>

<style scoped lang="less">
.dialog {
}
</style>
